import React from 'react'

const ContactUs = () => {
  return (
    <>
      <div className='contact-sec'>
           <div className='contact-inner'>
           <a href='tel:8657604581'>
           <div className='contact-item'>
               <i className="fa fa-phone" ></i>
               <p>Call Us</p>
               </div>
           </a>
           <a href='mailto:support@suprkid.in'>
               <div className='contact-item'>
               <i className="fa fa-envelope"></i>
               <p>Email Us</p>
               </div>
               </a>
               <a href='https://linktr.ee/suprkid' target="_blank" rel="noreferrer">
               <div className='contact-item'>
               <i className="fa fa-external-link"></i>
               <p>Our Socials</p>
               </div>
               </a>
               
           </div>
      </div>
         <div className='kid_section'>
        <div className='container'>
          <div className='kid_image'>
            <img src={process.env.PUBLIC_URL + "/images/kid.png"} alt="kid" className='img-resp'/>
          </div>
        </div>

      </div>
    </>
  )
}

export default ContactUs