import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import { Formik } from 'formik';
import swal from 'sweetalert';
// import MessengerCustomerChat from 'react-messenger-customer-chat';
const Footer = () => {
const [isLoading, setLoading] = useState(false);
const submitRequest = async(values, {resetForm})=>{
  setLoading(true)
   const rawResponse = await fetch('https://suprkidapi.articloo.com/api/query/submit', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(values)
  });
  const res = await rawResponse.json();
   if(res && res.status === true){
    setLoading(false)
    resetForm();
    swal("Thank You!", res.message, "success");
   }else{
    setLoading(false)
    swal("Sorry!", 'Something went wrong.', "error");
   }
 
}
  return (
    <>      <footer>
    <div className='container'>
      <div className='footer-top'>
        <div className='row'>
          <div className='col-md-6 col-sm-12'>
            <div className='footer-brand'>
              <img src={process.env.PUBLIC_URL + '/images/footerlogo.png'} alt="logo" />
              <div className='brand-content'>
                <p>We help you <br /> and your family <br /> stay fit, fun <br /> and happy
                </p>
              </div>
            </div>
          </div>
          <div className='col-md-6 col-sm-12'>
            <div className='query-form'>
              <div className='form-title'>
                <p>Any Queries?</p>
              </div>
             
              <Formik
       initialValues={{ query: '' }}
       validate={values => {
         const errors = {};
         if (!values.query) {
           errors.query = 'Query is required';
         }
         return errors;
       }}
       onSubmit={submitRequest}
     >
       {({
         values,
         errors,
         touched,
         handleChange,
         handleBlur,
         handleSubmit,
         isSubmitting,
         /* and other goodies */
       }) => (
         <form onSubmit={isLoading ? undefined : handleSubmit}>
         <div className='form-group'>
         <textarea 
              className='form-control' 
              placeholder='Type Here'
              name="query"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.query}
              />
              {errors.query && touched.query && <span className='help-error-2'>{errors.query}</span> }
         </div>
             
              <button type='submit'>{isLoading ? 'Please wait..': 'Submit' }</button>
              </form> 
              )}
     </Formik>
            </div>
          </div>
        </div>


      </div>
      <div className='footer-bottom'>
        <div className='row'>
          <div className='col-md-3 col-sm-6 col-xs-12 pd20'>
            <div className='footer-box'>
            <div className='footer-title'>
              <p>Contact</p>
            </div>
            <p>
              Maxx Prime LLP 2nd Floor, Good wood
              building, Patanwala
              estate, LBS marg,
              Ghatkopar (west),
              Mumbai - 400086</p>
            <p className='mt-20'>support@maxxwellness.in </p>
            </div>
            
          </div>
          <div className='col-md-3 col-sm-6 col-xs-12 pd20'>
          <div className='footer-box'>
            <div className='footer-title'>
              <p>Know more on</p>
            </div>
            <ul className='menu-list'>
              <li><Link to="/">Super Kid</Link></li>
              <li><Link to="/">Make Me Fit</Link></li>
              <li><Link to="/">Super Mom</Link></li>
              <li><a href="https://maxxwellness.in/blog" target="_blank"  rel="noreferrer">Maxx Blogs</a></li>
            </ul>
            </div>
          </div>
          <div className='col-md-3 col-sm-6 col-xs-12 pd20'>
          <div className='footer-box'>
            <div className='footer-title'>
              <p>Latest News</p>
            </div>
            <p>
              <p className='txt-gr'>March 10, 2022</p>
              <p>How to lose fat in
                5 weeks at home</p>
              <p className='txt-gr mt-10'>March 05, 2022</p>
              <p>Managing Family and
                Nourishing Self</p>
            </p>
            </div>
          </div>
          <div className='col-md-3 col-sm-6 col-xs-12 pd20'>
          <div className='footer-box'>
            <div className='footer-title'>
              <p>Stay in touch</p>
            </div>
            <ul className='social-links'>
              <li><a href="https://instagram.com/suprkidindia?igshid=YmMyMTA2M2Y=" target="_blank" rel="noreferrer"><i className="fa fa-instagram"></i></a></li>
              <li><a href="https://www.facebook.com/suprkidindia/" target="_blank" rel="noreferrer"><i className="fa fa-facebook"></i></a></li>
              <li><a href="/" target="_blank" rel="noreferrer"><i className="fa fa-pinterest-p"></i></a></li>
            </ul>
          </div>
          </div>
        </div>
      </div>
    </div>
    <div className='footer-arrow'>
  <img src={process.env.PUBLIC_URL + '/images/bottom-icon.png'} alt="bottom-arrow" />
  </div>
  </footer>
    <div className='whatsApp-link'>
     <a href="https://api.whatsapp.com/send/?phone=918657604581&text&app_absent=0" target="_blank" rel="noreferrer">
     <i className="fa fa-whatsapp" ></i>
     </a>
       
    </div>
    {/* <MessengerCustomerChat
    pageId="1115418875266965"
    appId="<APP_ID>"
  /> */}
  </>
  )
}

export default Footer