import React from 'react'

const ComingSoon = () => {
  return (
    <>
      <div className='contact-sec'>
           <div className='contact-inner'>
               <h1>Coming Soon</h1>
           </div>
      </div>
         <div className='kid_section'>
        <div className='container'>
          <div className='kid_image'>
            <img src={process.env.PUBLIC_URL + "/images/kid.png"} alt="kid" className='img-resp'/>
          </div>
        </div>

      </div>
    </>
  )
}

export default ComingSoon